



































































































































































































































import { UmbrellaData } from "@/mixins/umbrella";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import Pricing from "@/components/order_form/Pricing.vue";
import Share from "@/components/share/Share.vue";
import ConfigFileService from "@/services/config_file_service";
import { ConfigCreator } from "@/mixins/configurator";
import { APIError, BadRequest } from "@/services/error_service";
import Download from "@/components/download/Download.vue";
import { CustomUmbrella } from "@/models/configurator/custom_umbrella";
import { FabricSwatch } from "@/models/products/fabric";
import { Auth } from "@/mixins/auth";
import { Roles } from "@/models/user/roles";
import EditWarning from "@/components/request_a_quote/EditWarning.vue";
import { ProductLineName } from "@/models/products/collection";
import Configurator from "@/components/designer/Configurator.vue";
import LeadTime from "@/components/order_form/LeadTime.vue";
import {
  BaseStore,
  CanopyStore,
  FrameStore,
  LayoutStore,
  ShapeStore,
} from "@/mixins/store";

Component.registerHooks(["beforeRouteEnter"]);
@Component({
  components: {
    Pricing,
    Share,
    Download,
    EditWarning,
    Configurator,
    LeadTime,
  },
})
export default class RequestQuote extends mixins(
  UmbrellaData,
  ConfigCreator,
  Auth,
  LayoutStore,
  ShapeStore,
  CanopyStore,
  BaseStore,
  FrameStore
) {
  @Prop() props!: any;
  protected umbrella: CustomUmbrella | null = null;
  protected ProductLineName = ProductLineName;
  protected configFileService = new ConfigFileService();
  protected internalShareLink = "";
  protected publicShareLink = "";
  protected showShareDialog = false;
  protected showEditDialog = false;
  protected Roles = Roles;
  protected qty = 1;
  protected specs = "";
  protected loader: any = null;


  @Watch("qty")
  protected onQtyChange(): void {
    const frame = (this.$refs.machform as HTMLIFrameElement).contentWindow;
    if (frame) {
      // https://stackoverflow.com/questions/42376464/uncaught-domexception-failed-to-execute-postmessage-on-window-an-object-co
      const qty = JSON.parse(JSON.stringify({ qty: this.qty }));
      const link = JSON.parse(JSON.stringify({ link: this.internalShareLink }));
      const specs = JSON.parse(JSON.stringify({ specs: this.specs }));
      frame.postMessage(
        { ...qty, ...link, ...specs },
        "https://www.frankfordumbrellas.com"
      );
    }
  }

  protected sendFrameMsg(): void {
    const frame = (this.$refs.machform as HTMLIFrameElement).contentWindow;
    if (frame) {
      // https://stackoverflow.com/questions/42376464/uncaught-domexception-failed-to-execute-postmessage-on-window-an-object-co
      const qty = JSON.parse(JSON.stringify({ qty: this.qty }));
      const link = JSON.parse(JSON.stringify({ link: this.internalShareLink }));
      const specs = JSON.parse(JSON.stringify({ specs: this.specs }));
      frame.postMessage(
        { ...qty, ...link, ...specs },
        "https://www.frankfordumbrellas.com"
      );
    }
  }

  async created() {
    if (!this.props.img1 && this.$route.query.quickship) {
      this.$router.replace("/resubmit?quickship=true");
    } else if (!this.props.img1) {
      this.$router.replace("/resubmit");
    }
    this.umbrella = this.getUmbrellaObject();
    if (!this.umbrella) {
      this.$router.replace("/designer");
    }
    await this.getShareLink();
    await this.getSpecs();
  }

  protected minusQty(): void {
    if (this.qty > 1) {
      this.qty--;
    }
  }

  protected addQty(): void {
    if (this.qty < 9999) {
      this.qty++;
    }
  }

  get image(): string {
    return this.props.img1;
  }

  protected closeShareModal(): void {
    this.showShareDialog = false;
  }

  public makeTitleCase(handle: string) {
    const words = handle.split(" ");
    return words
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(" ");
  }

  protected closeEditModal(): void {
    this.showEditDialog = false;
  }
  protected editConfig(): void {
    if (this.$route.query.quickship) {
      this.showEditDialog = true;
    } else {
      this.$router.replace("/designer/canopy");
    }
  }

  protected async share(): Promise<void> {
    this.showShareDialog = false;
    this.loader = this.$loading.show(
      {},
      { before: this.$createElement("h1", "Generating share link...") }
    );
    await this.getShareLink();
    setTimeout(() => {
      this.loader.hide();
      this.showShareDialog = true;
    }, 1000);
  }

  protected async getSpecs(): Promise<void> {
    let str = "";
    str += `Collection: ${this.collection.display_name}`;
    str += `\n Layout:  ${this.preset}`;
    str += `\n Model:  ${
      this.umbrellaModel ? this.umbrellaModel.model : "N/A"
    }`;
    str += `\n Base Model:  ${this.baseModel ? this.baseModel.model : "N/A"}`;
    str += `\n Base Finish:  ${
      this.baseFinish
        ? `${this.baseFinish.display_name}, (${this.baseFinish.handle})`
        : "N/A"
    }`;
    str += `\n Base Stem:  ${this.baseStem ? this.baseStem : "N/A"}`;
    str += `\n Base Options:  ${this.baseOptions ? this.baseOptions : "N/A"}`;
    str += `\n Main Canopy:  ${this.mainCanopy ? this.mainCanopy : "N/A"}`;
    str += `\n Main Fabric:  ${
      this.mainFabric.length > 0
        ? this.mainFabric.map((f) => " " + f.mfr_code).toString()
        : "N/A"
    }`;
    str += `\n Top Vent Fabric:  ${
      this.ventTopFabric.length > 0
        ? this.ventTopFabric.map((f) => " " + f.mfr_code).toString()
        : "N/A"
    }`;
    str += `\n Middle Vent:  ${this.ventMiddle ? this.ventMiddle : "N/A"}`;
    str += `\n Middle Vent Fabric: ${
      this.ventMiddleFabric.length > 0
        ? this.ventMiddleFabric.map((f) => " " + f.mfr_code).toString()
        : "N/A"
    }`;
    str += `\n Valance: ${this.valance ? this.valance : "N/A"}`;
    str += `\n Valance Fabric: ${
      this.valanceFabric.length > 0
        ? this.valanceFabric.map((f) => " " + f.mfr_code).toString()
        : "N/A"
    }`;
    str += `\n Binding Fabric:  ${
      this.bindingFabric ? this.bindingFabric.mfr_code : "N/A"
    }`;
    str += `\n Rib:  ${this.rib ? this.rib : "N/A"}`;
    str += `\n Main Trim Fabric:  ${
      this.mainTrimFabric ? this.mainTrimFabric.mfr_code : "N/A"
    }`;
    str += `\n Main Trim Fabric (Inner):  ${
      this.mainTrimFabricInner ? this.mainTrimFabricInner.mfr_code : "N/A"
    }`;
    str += `\n Vent Trim Fabric:  ${
      this.ventTrimFabric ? this.ventTrimFabric.mfr_code : "N/A"
    }`;
    str += `\n Finish: ${
      this.finish
        ? `${this.finish.display_name} (${this.finish.handle})`
        : "N/A"
    }`;
    str += `\n Finial: ${this.finial ? this.finial : "N/A"}`;
    str += `\n Finial Finish:  ${
      this.finialFinish
        ? `${this.finialFinish.display_name} (${this.finialFinish.handle})`
        : "N/A"
    }`;
    str += `\n Bottom Pole:  ${this.bottomPole ? this.bottomPole : "N/A"}`;
    this.specs = str;
  }

  protected async getShareLink(): Promise<void> {
    try {
      const body = await this.transformDataToConfigFile();
      if (!this.internalShareLink) {
        const res = await this.configFileService.createShareFile(body, false);
        this.internalShareLink =
          process.env.VUE_APP_SHARE_LINK + "/view?design=" + res.share_id;
      } else {
        const publicBody = { ...body, image: this.image, stock: this.isStockOnly };
        const res = await this.configFileService.createShareFile(publicBody, true);
        this.publicShareLink =
          process.env.VUE_APP_SHARE_LINK + "/view?design=" + res.share_id;
      }
    } catch (err) {
      if (err instanceof BadRequest && !this.internalShareLink) {
        APIError.redirect(
          "We are unable to generate a quote request for this umbrella. Please contact Frankford Umbrellas directly at info@frankfordumbrellas.com."
        );
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
  }

  protected getFabricNames(fabrics: FabricSwatch[]): string {
    const names = fabrics.map((swatch) => swatch.name);
    return names.join(", ");
  }
  protected formatTitle(title: string, part: string): string {
    let result = "";
    if (title.indexOf(" ")) {
      if (part == "a") {
        result = title.substr(0, title.indexOf(" "));
      } else {
        result = title.substr(title.indexOf(" ") + 1);
      }
    } else {
      return title;
    }

    return result;
  }
}
