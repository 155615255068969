


























import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class EditWarning extends Vue {
  @Prop({ default: false }) showDialog!: boolean;
  protected show = false;

  @Watch("showDialog")
  protected isOpen(): void {
    this.show = this.showDialog;
  }

  protected closeDialog(): void {
    this.$emit("close");
  }
}
